<div class="loginForm" (keyup.enter)="next()">
    <div *ngIf="!isLoading">
        <div class="title">Ziti Admin Console</div>
        <div class="subtitle">Welcome, please login to continue</div>

        <ng-container *ngIf="edgeCreate; else userLogin">
            <lib-string-input [fieldName]="'Edge Controller Name'"
                        [(fieldValue)]="edgeName" [error]="edgeNameError"
                        [placeholder]="'enter a name for your controller'"></lib-string-input>
            <lib-string-input [fieldName]="'URL'"
                        [(fieldValue)]="edgeUrl" [error]="edgeUrlError"
                        [placeholder]="'e.g. http://10.0.0.1:1280'"></lib-string-input>
            <div id="CreateArea" class="edgecreate buttons">
                <div id="BackToLogin" *ngIf="backToLogin" class="linkButton" (click)="reset()">Back To Login</div>
                <button class="button" (click)="create()" [disabled]="!edgeName ||!edgeUrl">Set Controller</button>
            </div>
        </ng-container>

        <ng-template #userLogin>
            <lib-selector-input
                        *ngIf="!svc.originIsController"
                        [fieldName]="'Edge Controller'"
                        [(fieldValue)]="selectedEdgeController"
                        [error]="edgeNameError"
                        [placeholder]="'Connect to a new Edge Controller'"
                        [valueList]="edgeControllerList"
                        (fieldValueChange)="edgeChanged($event)"
            >
            </lib-selector-input>
            <lib-string-input [fieldName]="'Username'"
                        [(fieldValue)]="username"
                        [placeholder]="'enter username of controller'"></lib-string-input>
            <lib-password-input [fieldName]="'Password'"
                        [(fieldValue)]="password"
                        [placeholder]="'enter password of controller'"></lib-password-input>

            <div class="buttons">
                <button class="button" (click)="login()">Login</button>
            </div>
        </ng-template>
    </div>
</div>
<lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>
