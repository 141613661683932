<div class="hidden-columns-bar-container">
    <div class="hidden-columns-list" id="ColumnVisibilityHeader">
        <div
                (click)="setColumnVisibilityColumn(col, true)"
                *ngFor="let col of hiddenColumns"
                class="hidden-column-item ag-dnd-ghost ag-unselectable ag-theme-alpine"
                id="ColumnVisibility_{{ col.headerName }}"
                title="Show column"
        >
        <span class="ag-dnd-ghost-icon">
            <span class="ag-icon ag-icon-eye-slash"></span>
        </span>
            <div class="ag-dnd-ghost-label">{{ col.headerName }}</div>
        </div>
    </div>
</div>