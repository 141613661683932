<div id="ResetModal" class="reset-enrollment-container modal" style="height: fit-content;">
    <div class="close icon-close" (click)="cancel()"></div>
    <div class="reset-title-container">
        <div class="title">{{type === 'reset' ? 'Reset' : 'Reissue'}} Enrollment</div>
        <div class="subtitle">Select a date to {{type === 'reset' ? 'reset' : 'reissue'}} the enrollment token</div>
    </div>
    <div class="reset-content-container">
        <label>Date Enrollment Expires</label>
        <p-calendar
                [(ngModel)]="dateValue"
                dateFormat="mm/dd/yy"
                selectionMode="single"
                [showIcon]="showIcon"
                [showTime]="true"
                [minDate]="minDate"
                placeholder="Select Time Range"
                #calendar
        ></p-calendar>
    </div>
    <div class="reset-buttons-container">
        <div id="ResetEnrollButton" class="button" (click)="handleAction()">{{type === 'reset' ? 'Reset' : 'Reissue'}} Enrollment</div>
    </div>
</div>