<div class="card-list-wrapper ziti-page-container fullModal opened">
    <div class="title" style="filter: brightness(50%)">Create a service</div>
    <div class="subtitle">Choose a service type</div>
    <div class="card-list-container">
        <lib-card
            (click)="cardSelected('simple')"
            [imageUrl]="'/assets/svgs/Simple.svg'"
            [title]="'Simple'"
            [content]="'Simple services required fewer details, only asking for the most commonly used fields.'"
            [buttonText]="'Create Simple Service'"
            [id]="'SimpleServiceCard'"
            [href]="'services/simple'"
        ></lib-card>
        <lib-card
            (click)="cardSelected('advanced')"
            [imageUrl]="'/assets/svgs/Advanced.svg'"
            [title]="'Advanced'"
            [content]="'Advanced services allow you to configure any option supported by the controller but are subsequently a bit more complex.'"
            [buttonText]="'Create Advanced Service'"
            [id]="'AdvancedServiceCard'"
            [href]="'services/advanced'"
        ></lib-card>
    </div>
    <div (click)="returnToListPage()" class="buttonBall close" id="EscZitiAWClose">
        <div class="buttonLabel">ESC</div>
    </div>

</div>
