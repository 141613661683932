<div class="projectable-form-wrapper"
     (keyup.enter)="save($event)"
     (keyup.escape)="closeModal(false)"
>
    <lib-form-header
            [data]="formData"
            [title]="formData.id ? 'Edit Service Policy: ' : 'Create New Service Policy'"
            [moreActions]="moreActions"
            (actionRequested)="headerActionRequested($event)"
            [(formView)]="formView"
    ></lib-form-header>
    <div class="service-policy-form-container projectable-form-container">
        <div class="projectable-form-main-column form-group-row" *ngIf="formView === 'simple'">
            <div class="form-group-column three-fifths">
                <lib-form-field-container [title]="'Service Policy Name'" [label]="'REQUIRED'">
                    <input
                            class="form-field-input"
                            placeholder="Name this edge service policy"
                            [ngClass]="{error: errors['name']}"
                            [(ngModel)]="formData.name"
                            autofocus
                            #nameFieldInput
                    />
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Select Service Attributes'"
                        [label]="'OPTIONAL'"
                        [contentStyle]="'z-index: 100003'"
                        [helpText]="'Choose which services to associate to this policy by picking from the list of available attributes'"
                >
                    <lib-tag-selector
                            [(selectedRoleAttributes)]="selectedServiceRoleAttributes"
                            [(selectedNamedAttributes)]="selectedServiceNamedAttributes"
                            [availableRoleAttributes]="svc.serviceRoleAttributes"
                            [availableNamedAttributes]="svc.serviceNamedAttributes"
                            (selectedNamedAttributesChange)="svc.getAssociatedServicesByAttribute(selectedServiceRoleAttributes, selectedServiceNamedAttributes)"
                            (selectedRoleAttributesChange)="svc.getAssociatedServicesByAttribute(selectedServiceRoleAttributes, selectedServiceNamedAttributes)"
                            [placeholder]="'Select service attributes'"
                    ></lib-tag-selector>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Select Identity Attributes'"
                        [label]="'OPTIONAL'"
                        [contentStyle]="'z-index: 100002'"
                        [helpText]="'Choose which identities to associate to this policy by picking from the list of available attributes'"
                >
                    <lib-tag-selector
                            [(selectedRoleAttributes)]="selectedIdentityRoleAttributes"
                            [(selectedNamedAttributes)]="selectedIdentityNamedAttributes"
                            [availableRoleAttributes]="svc.identityRoleAttributes"
                            [availableNamedAttributes]="svc.identityNamedAttributes"
                            (selectedNamedAttributesChange)="svc.getAssociatedIdentitiesByAttribute(selectedIdentityRoleAttributes, selectedIdentityNamedAttributes)"
                            (selectedRoleAttributesChange)="svc.getAssociatedIdentitiesByAttribute(selectedIdentityRoleAttributes, selectedIdentityNamedAttributes)"
                            [placeholder]="'Select identity attributes'"
                    ></lib-tag-selector>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Select Posture Check Attributes'"
                        [label]="'OPTIONAL'"
                        [contentStyle]="'z-index: 100001'"
                        [helpText]="'Choose which posture checks to associate to this policy by picking from the list of available attributes'"
                >
                    <lib-tag-selector
                            [(selectedRoleAttributes)]="selectedPostureRoleAttributes"
                            [(selectedNamedAttributes)]="selectedPostureNamedAttributes"
                            [(selectedRoleAttributes)]="svc.postureRoleAttributes"
                            [availableNamedAttributes]="svc.postureNamedAttributes"
                            (selectedNamedAttributesChange)="svc.getAssociatedPostureChecksByAttribute(selectedPostureRoleAttributes, selectedPostureNamedAttributes)"
                            (selectedRoleAttributesChange)="svc.getAssociatedPostureChecksByAttribute(selectedPostureRoleAttributes, selectedPostureNamedAttributes)"
                            [placeholder]="'Select posture check attributes'"
                    ></lib-tag-selector>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Type'"
                        [title2]="'Semantic'"
                        [layout]="'row'"
                >
                    <select
                            [(ngModel)]="formData.type"
                            id="PolicyType"
                            class="form-field-dropdown"
                    >
                        <option value="Bind">Bind</option>
                        <option value="Dial">Dial</option>
                    </select>
                    <select
                            [(ngModel)]="formData.semantic"
                            id="PolicySemantic"
                            class="form-field-dropdown"
                    >
                        <option value="AnyOf">AnyOf</option>
                        <option value="AllOf">AllOf</option>
                    </select>
                </lib-form-field-container>
                <lib-form-field-toggle [(toggleOn)]="showMore" (toggleOnChange)="showMoreChanged($event)" style="margin: 0px 10px"></lib-form-field-toggle>
                <div [hidden]="!showMore" class="form-group-column">
                    <lib-form-field-container
                            [title]="'Custom Tags'"
                            [label]="'OPTIONAL'"
                            class="form-field-advanced"
                            *ngIf="!hideTags"
                    >
                        <lib-custom-tags [(tags)]="formData.tags"></lib-custom-tags>
                    </lib-form-field-container>
                </div>
            </div>
            <div class="form-group-column two-fifths">
                <lib-form-field-container
                        [title]="'Associated Services'"
                        [count]="svc.associatedServiceNames.length"
                        [class]="'hide-overflow'"
                        [helpText]="'Preview list of all services that this Service Policy is associated with.'"
                >
                    <lib-preview-list
                            [clickable]="false"
                            [isLoading]="servicesLoading"
                            [allNames]="svc.associatedServiceNames"
                    ></lib-preview-list>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Associated Identities'"
                        [count]="svc.associatedIdentityNames.length"
                        [helpText]="'Preview list of all Identities that this Service Policy is associated with.'"
                >
                    <lib-preview-list
                            [clickable]="false"
                            [isLoading]="identitiesLoading"
                            [allNames]="svc.associatedIdentityNames"
                    ></lib-preview-list>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Associated Posture Checks'"
                        [count]="svc.associatedPostureCheckNames.length"
                        [helpText]="'Preview list of all Posture Checks that this Service Policy is associated with.'"
                >
                    <lib-preview-list
                            [clickable]="false"
                            [isLoading]="postureChecksLoading"
                            [allNames]="svc.associatedPostureCheckNames"
                    ></lib-preview-list>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'API Calls'"
                        [headerActions]="apiOptions"
                        (actionRequested)="apiActionRequested($event)"
                >
                    <div class="form-row">
                        <input class="form-field-input" [value]="apiCallURL"/>
                        <div class="icon-copy copy" (click)="copyToClipboard(apiCallURL)"></div>
                    </div>
                    <lib-json-view *ngIf="formData" [(data)]="apiData" [readOnly]="true" [showCopy]="true"></lib-json-view>
                </lib-form-field-container>
            </div>
        </div>
        <div class="form-group-column" *ngIf="formView === 'raw'">
            <lib-json-view *ngIf="formData" [(data)]="formData"></lib-json-view>
        </div>
    </div>
</div>
<lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>