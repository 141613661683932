<div class="ziti-page-container ziti-configs-container">
    <lib-list-page-header [title]="title"
                          [tabs]="tabs"
                          [showAdd]="!itemsSelected"
                          (actionClicked)="headerActionClicked($event)"></lib-list-page-header>

    <lib-data-table [tableId]="'configurations'"
                    [rowData]="rowData"
                    [columnDefinitions]="columnDefs"
                    (actionRequested)="tableAction($event)"
                    [startCount]="startCount"
                    [endCount]="endCount"
                    [totalCount]="totalCount"
                    [emptyMsg]="'No Configurations defined, Click the plus to add a Configuration.'"
                    [filterApplied]="filterApplied"
                    [menuItems]="svc.menuItems"
                    (gridReady)="gridReady($event)"
    >
    </lib-data-table>
</div>
