<label>PORT RANGES</label>
<p-chips
        (onAdd)="validateConfig()"
        (onRemove)="validateConfig()"
        (keyup)="onKeyup($event)"
        [(ngModel)]="fieldValue"
        [allowDuplicate]="false"
        [ngClass]="{ error: invalid }"
        [placeholder]="'80 120-125 443 8080'"
        [addOnBlur]="true"
        separator=","
>
</p-chips>