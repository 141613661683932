<div [ngClass]="{ 'full-screen': fullScreen }" class="modal solid" id="IdentityVisualizerDataModal">
    <div class="tp-data-header">
        <div class="modaltitle">Identity Service Path</div>
        <div (click)="hide()" class="buttonBall icon-close" id="EscZEndpointClose"></div>
        <div (click)="toggleModalSize()" class="buttonBall resize" id="ResizeModalButton"></div>
    </div>

    <div class="identity-topology-container">

        <div style="width: 100%; height: 80px; float: left">
            <div class="ep-topology-filter-container listBox row">
                <input
                    (keyup)="filterSearchArray()"
                    [(ngModel)]="selectedService"
                    [matAutocomplete]="auto"
                    class="topology-filter-input"
                    id="filterText"
                    matInput
                    placeholder="Type to search identity accessible services."
                />

                <div
                    (click)="clearSearchFilter()"
                    *ngIf="filterText"
                    class="clear-svc-icon icon-close"
                    id="ClearSearchFilter"
                ></div>
                <div [hidden]="!noSearchResults" class="no-results-warning">No Results Found</div>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="autocompleteSearch($event)">
                    <mat-option *ngFor="let option of autocompleteOptions" [value]="option.name">
                        {{ option.name }}
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>

            <div class="topotip" id="epTooltip"></div>

            <svg style='pointer-events:all' height="1000" id="IdentityTopology" width="1200">
            </svg>
            <lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>

        <div class="topology-legendA">
            <div class="legend-item-head">
                <div class="legend-text-heading">Component Status</div>
            </div>
            <div class="legend-item">
                <div class="legend-circle unregistered"></div>
                <div class="legend-text">Unregistered / Offline</div>
            </div>
            <div class="legend-item">
                <div class="legend-circle registered_in_use"></div>
                <div class="legend-text">Registered, Online</div>
            </div>
            <div class="legend-item">
                <div class="legend-circle errored"></div>
                <div class="legend-text">Errored</div>
            </div>
        </div>

        <div class="topology-legendB">
            <div class="legend-item-head">
                <div class="legend-text-heading">Link Status</div>
            </div>
            <div class="legend-item">
                <div class="legend-circle unregistered"></div>
                <div class="legend-text">Offline</div>
            </div>
            <div class="legend-item">
                <div class="legend-circle registered_in_use"></div>
                <div class="legend-text">In Usage</div>
            </div>
            <div class="legend-item">
                <div class="legend-circle registered_not_in_use"></div>
                <div class="legend-text">Usage Date N/A</div>
            </div>
            <div class="legend-item">
                <div class="legend-circle linkerrored"></div>
                <div class="legend-text">Errored</div>
            </div>
        </div>

    </div>
</div>
